import { useCallback, useEffect, useMemo, useState } from "react";

import ProductsTable from "../commerce-table/products-table";

import { ReactComponent as DarkPencilIcon } from "./../../assests/pencil.svg";
import { ReactComponent as DarkTrashCanIcon } from "./../../assests/trashcan.svg";
import { ReactComponent as DarkRoundedMenuIcon } from "./../../assests/rounded-hamburger-menu.svg";

// import "./commerce-table-tabs.scss";
import { Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sortProductsTable } from "../../custom hooks/sort-table/sort-products-table";
import { searchTable } from "../../custom hooks/search-table/search-table";
import Swal from "sweetalert2";
import { deletePlan, getPlans } from "../../firebase/firebase.utils";
import { Stack } from "react-bootstrap";
import TableFilters from "../table/table-filters";
import { Box } from "@mui/material";
import PlansTable from "./PlansTable";
import { addPlans } from "../../redux/plan/actions/actions";

const PlansTableContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxPlans = useSelector((state) => state.plans);

  const matchesUp1200 = useMediaQuery("(min-width:1200px)");

  const [plans, setPlans] = useState([]);
  const { insurers } = useSelector((state) => state.insurers);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  const headers = [
    { name: "Nombre", accessor: "planName" },
    { name: "Monto", accessor: "amount" },
    { name: "Precio", accessor: "price" },
    { name: "Precio +65", accessor: "price2" },
    { name: "Precio +75", accessor: "price3" },
    { name: "Aseguradora", accessor: "insurer" },
    //{ name: "Total", accessor: "total" },
    { name: "Deducible", accessor: "deductible" },
    { name: "En la app", accessor: "status" },
    { name: "Acciones", accessor: "actions" },
  ];
  const headersSerch = [
    { name: "Nombre", accessor: "planName" },
    { name: "Monto", accessor: "amount" },
    { name: "Precio", accessor: "price" },
    { name: "Precio +65", accessor: "price2" },
    { name: "Precio +75", accessor: "price3" },
    { name: "Aseguradora", accessor: "insurer" },
    //{ name: "Total", accessor: "total" },
    { name: "Deducible", accessor: "deductible" },
    { name: "MO", accessor: "status" },
  ];

  const handleClick = (url) => {
    navigate(url);
  };

  const getPrice = (finalPrice) => {
    const price = finalPrice / 1.13;

    return price.toFixed(2);
  };

  const getInsurerName = (insurerId) => {
    if (!insurers) {
      return;
    }
    const insurerData = insurers.find((insurer) => insurer.id === insurerId);

    return insurerData.name;
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-danger me-2",
      cancelButton: "btn btn-secondary",
    },
    buttonsStyling: false,
  });

  const callDeletePlan = (id) => {
    swalWithBootstrapButtons
      .fire({
        title: "¿Desea eliminar este plan?",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Eliminar",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deletePlan(id);
          Swal.fire("Plan eliminado", "", "success");
          getAllPlans();
        }
      });
  };

  const newPlansList = useMemo(
    () =>
      filteredPlans.map((plan) => {
        //console.log(product);
        return {
          id: plan.id,
          planName: plan.name ? plan.name : "--",
          amount: plan.amount ? plan.amount : "--",
          price: plan.price ? plan.price : 0,
          price2: plan.price2 ? plan.price2 : 0,
          price3: plan.price3 ? plan.price3 : 0,
          insurer: plan.insurerId ? getInsurerName(plan.insurerId) : "--",
          // tax: plan.iva ? (
          //   <span className="text-active">{plan.iva + "%"}</span>
          // ) : (
          //   <span className="text-active">13%</span>
          // ),
          taxAmount: plan.finalPrice
            ? (plan.finalPrice - getPrice(plan.finalPrice)).toFixed(2)
            : 0,
          deductible: plan.deductible ? (
            <span className="text-active">Si</span>
          ) : (
            <span className="text-danger">No</span>
          ),
          apportionment: plan.apportionment === "Si" ? "Si" : "No",
          status: plan.isActive ? (
            <span className="text-active">Mostrado</span>
          ) : (
            <span className="text-danger">No Mostrado</span>
          ),
          actions: (
            <Stack direction="horizontal" gap={2} key={plan.id}>
              <span variant="secondary" size="sm" className="text-light">
                <DarkPencilIcon
                  onClick={() => handleClick(`edit-plan/${plan.id}`)}
                />
              </span>
              <span variant="secondary" size="sm" className="text-light">
                <DarkTrashCanIcon onClick={() => callDeletePlan(plan.id)} />
              </span>
              <span variant="secondary" size="sm" className="text-light">
                <DarkRoundedMenuIcon />
              </span>
            </Stack>
          ),
        };
      }),
    [filteredPlans, insurers]
  );

  const [itemsPerPage, setItemsPerPage] = useState(5);
  //const [filteringBy, setfilteringBy] = useState("Name");

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + Number(itemsPerPage);
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(newPlansList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(newPlansList.length / Number(itemsPerPage)));
  }, [itemOffset, newPlansList, itemsPerPage]);

  const getAllPlans = useCallback(async () => {
    setLoading(true);
    const planList = await getPlans();

    setPlans(planList);
    setFilteredPlans(plans);
    dispatch(addPlans(planList));
  }, [dispatch, plans]);

  useEffect(() => {
    setLoading(true);
    if (reduxPlans.plans) {
      const { plans } = reduxPlans;
      setPlans(plans);
      setFilteredPlans(plans);
      setLoading(false);
    } else {
      getAllPlans();
    }
  }, [reduxPlans]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % newPlansList.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const sortTableElements = (sortingMethod) => {
    const sortedItems = sortProductsTable(currentItems, sortingMethod);
    setCurrentItems(sortedItems);
  };

  const dropdownList = [
    {
      valuesList: [
        { name: "IVA + a -" },
        { name: "IVA - a +" },
        { name: "Precio + a -" },
        { name: "Precio - a +" },
      ],
      label: "",
      filterMethod: sortTableElements,
    },
  ];

  const showActivePlans = (list) => {
    const activeProducts = list.filter((product) => product.isActive);

    setFilteredPlans(activeProducts);
  };

  const showInactivePlans = (list) => {
    const inactiveProducts = list.filter((product) => !product.isActive);

    setFilteredPlans(inactiveProducts);
  };

  const showAllPlans = (list) => {
    setFilteredPlans(list);
  };

  const options = [
    { text: "Todos", filterMethod: () => showAllPlans(plans) },
    { text: "Activo", filterMethod: () => showActivePlans(plans) },
    { text: "Inactivo", filterMethod: () => showInactivePlans(plans) },
  ];

  const [searchField, setSearchField] = useState("");
  const rows = searchTable(currentItems, headersSerch, searchField);

  return (
    <>
      <Typography variant="h5" component="h2" my={3}>
        Planes
      </Typography>

      {matchesUp1200 ? null : (
        <Box mb={-4}>
          <TableFilters
            searchRows={setSearchField}
            dropdowns={dropdownList}
            handleRefresh={() => getAllPlans()}
            showOnLargeScreens={true}
            product="plan"
          />
        </Box>
      )}

      <PlansTable
        setSearchField={setSearchField}
        dropdownList={dropdownList}
        options={options}
        getAllProducts={getAllPlans}
        loading={loading}
        headers={headers}
        rows={rows}
        pageCount={pageCount}
        handlePageClick={handlePageClick}
        setItemsPerPage={setItemsPerPage}
      />
    </>
  );
};

export default PlansTableContainer;
