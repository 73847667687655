import { Routes, Route, Navigate } from "react-router-dom";

import Users from "../routes/users-list/users";
import User from "../routes/user/user";
import LogIn from "../routes/login/login";
import Sponsors from "../routes/sponsors/sponsors";
import Sponsor from "../routes/sponsor/sponsor";
import Causes from "../routes/causes/causes";
import Cause from "../routes/cause/cause";
import Achievements from "../routes/achievements/achievements";
import Roles from "../routes/roles/roles";
import Commerce from "../routes/commerce/commerce";
import AddProduct from "../routes/add-product/add-product";
import Home from "../routes/Home/home";

import CoinLoader from "../components/coin-loader/coin-loader";

import { ProtectedRoute } from "../routes/protected-routes/protected-routes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUserSuccess, setIsLoadingAuth } from "../redux/user/actions/auth";
import { auth } from "../firebase/firebase-config";
import SidebarLayout from "../routes/navigation/SidebarLayout";
import Advertising from "../routes/advertising/Advertising";
import B2bUsers from "../routes/b2b/B2bUsers";
import B2bProfile from "../routes/b2b/B2bProfile";
import Products from "../routes/products/Products";
import SponsorRequests from "../routes/sponsors/SponsorRequests";
import BlogsPage from "../routes/blogs/BlogsPage";
import PostsPage from "../routes/blogs/PostsPage";
import Plans from "../routes/plans/Plans";
import AddPlan from "../routes/addPlan/AddPlan";

function AppRoutes() {
  const dispatch = useDispatch();
  const { loggedIn, isLoadingAuth } = useSelector((state) => state.auth);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idToken = await user.getIdToken(true);
        const claims = await user.getIdTokenResult();

        // Check if the user is a superadmin or admin based on claims
        if (
          claims.claims &&
          (claims.claims.superadmin || claims.claims.admin)
        ) {
          dispatch(loginUserSuccess(user.toJSON(), true, idToken, user));
        } else {
          // If the user doesn't have the necessary claims, handle it accordingly
          dispatch(setIsLoadingAuth(false));
          // You can also dispatch an action to set a flag indicating non-admin users.
          // dispatch(setNonAdminUser());
        }
      } else {
        dispatch(setIsLoadingAuth(false));
      }
    });

    // Cleanup the listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  if (isLoadingAuth) {
    const size = {
      width: 100,
      height: 100,
    };
    return <CoinLoader size={size} fullScreen={true} />;
  }

  return (
    <Routes>
      <Route path="/" element={<SidebarLayout />}>
        <Route
          path=""
          element={
            loggedIn ? (
              <Navigate replace to="/home" />
            ) : (
              <Navigate replace to="/auth/login" />
            )
          }
        />

        <Route
          path="/*"
          element={
            <ProtectedRoute isLoggedIn={loggedIn}>
              <Routes>
                <Route
                  path="*"
                  element={
                    <h1
                      className="d-flex align-items-center vh-100"
                      style={{ marginLeft: "30%" }}
                    >
                      Aqui no hay nada (ㆆ_ㆆ): 404!
                    </h1>
                  }
                />
                <Route
                  path="/auth/login"
                  element={<Navigate replace to="/home" />}
                />
                <Route index path="/home" element={<Home />} />
                <Route path="/user" element={<Users />} />
                <Route path="/b2b" element={<B2bUsers />} />
                <Route path="/b2b/:id" element={<B2bProfile />} />
                <Route path="/user/:id" element={<User />} />
                <Route path="/sponsors" element={<Sponsors />} />
                <Route path="/sponsorRequests" element={<SponsorRequests />} />
                <Route path="/business/:id" element={<Sponsor />} />
                <Route path="/causes" element={<Causes />} />
                <Route path="/achievements" element={<Achievements />} />
                <Route
                  path="/cause/:causeId/organization/:organizationId"
                  element={<Cause />}
                />
                <Route path="/roles" element={<Roles />} />
                <Route path="/commerce" element={<Commerce />} />
                <Route path="/products" element={<Products />} />
                <Route path="/plans" element={<Plans />} />
                <Route
                  path="/products/edit-product/:id"
                  element={<AddProduct />}
                />
                <Route path="/plans/add-plan/" element={<AddPlan />} />
                <Route path="/plans/edit-plan/:id" element={<AddPlan />} />
                <Route
                  path="/commerce/edit-product/:id"
                  element={<AddProduct />}
                />
                <Route path="/products/add-product/" element={<AddProduct />} />
                <Route path="/commerce/add-product/" element={<AddProduct />} />
                <Route path="/advertising" element={<Advertising />} />
                <Route path="/blogs" element={<BlogsPage />} />
                <Route path="/blogs/:id" element={<PostsPage />} />
              </Routes>
            </ProtectedRoute>
          }
        />
      </Route>

      {!loggedIn && <Route path="/auth/login" element={<LogIn />}></Route>}
    </Routes>
  );
}

export default AppRoutes;
