import { db, auth, storageRef } from "./firebase-config";

export const getDisplayNumber = () => {
  try {
    const displayNumber = db.collection("banners").doc("exchange").get();
    return displayNumber;
  } catch (error) {}
};

//Getting all instances
// export const getAllUsers = async () => {
//   try {
//     const usersToArray = [];
//     const itemsFromFs = await db.collection("users").get();

//     for (const user of itemsFromFs.docs) {
//       const docRef = user.ref;
//       const id = docRef.id;

//       const res = await db
//         .collection("users")
//         .doc(id)
//         .collection("private")
//         .doc("wallet")
//         .get();

//       const privateData = await db
//         .collection("users")
//         .doc(id)
//         .collection("private")
//         .doc("data")
//         .get();

//       const wallet = res.data();
//       const privateD = privateData.data();

//       if (wallet) {
//         const coinsDB = wallet.sportCoins;

//         usersToArray.push({
//           coins: coinsDB,
//           phone: privateD?.phone || '',
//           uid: id,
//           ...user.data(),
//         });
//       } else {
//         usersToArray.push({
//           sponsorId: docRef.id,
//           coins: "0",
//           phone: privateD?.phone || '',
//           id: user.id,
//           ...user.data(),
//         });
//       }
//     }

//     return usersToArray;
//   } catch (error) {
//     console.log(error);
//   }
// };
export const getAllUsers = async () => {
  try {
    const usersToArray = [];
    const itemsFromFs = await db.collection("users").get();
    itemsFromFs.docs.forEach((user) =>
      usersToArray.push({ id: user.id, ...user.data() })
    );
    return usersToArray;
  } catch (error) {
    console.log(error);
  }
};
export const getAllBanners = () => {
  try {
    const query = db
      .collection("banners")
      .doc("exchange")
      .collection("exchangeCarousel")
      .orderBy("itemNumber");

    return query;
  } catch (error) {}
};

export const getAllMembers = async () => {
  try {
    const membersToArray = [];
    const itemsFromFs = await db
      .collection("admins")
      .where("isRootAdmin", "==", true)
      .get();

    itemsFromFs.docs.forEach((member) =>
      membersToArray.push({ id: member.id, ...member.data() })
    );
    return membersToArray;
  } catch (error) {
    console.log(error);
  }
};

export const getAllAchievements = async () => {
  try {
    const achievementsToArray = [];
    const itemsFromFs = await db.collection("achievements").get();
    itemsFromFs.docs.forEach((achievement) => {
      achievementsToArray.push({ id: achievement.id, ...achievement.data() });
    });
    return achievementsToArray;
  } catch (error) {
    console.log(error);
  }
};

export const getAllSponsors = async () => {
  try {
    const sponsorsToArray = [];

    const itemsFromFs = await db.collection("sponsors").get();

    itemsFromFs.docs.forEach(async (business) => {
      const docRef = business.ref;
      const id = docRef.id;
      const res = await db
        .collection("sponsors")
        .doc(`${id}`)
        .collection("private")
        .doc("wallet")
        .get();

      const exchanges = [];
      const exch = await db
        .collection("sponsors")
        .doc(`${id}`)
        .collection("exchanges")
        .get();

      exch.docs.forEach((doc) => {
        exchanges.push({ id: doc.id, ...doc.data() });
      });

      const wallet = res.data();

      if (wallet) {
        const coinsDB = wallet.redeemedCoins;

        sponsorsToArray.push({
          sponsorId: docRef.id,
          coins: coinsDB,
          exchangesNumber: exchanges.length,
          id: business.id,
          ...business.data(),
        });
      } else {
        sponsorsToArray.push({
          sponsorId: docRef.id,
          coins: "0",
          id: business.id,
          ...business.data(),
        });
      }
    });

    return sponsorsToArray;
  } catch (error) {
    console.log(error);
  }
};

export const getAllExchanges = async (sponsorId) => {
  try {
    const exchangesCollection = await db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("exchanges")
      .get();

    const exchanges = [];

    exchangesCollection.docs.forEach((doc) => {
      exchanges.push({ id: doc.id, ...doc.data(), selected: false });
    });

    return exchanges;
  } catch (error) {
    console.log(error);
  }
};

export const getExchangesRealTime = (sponsorId) => {
  try {
    const query = db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("exchanges");

    return query;
  } catch (error) {
    console.log(error);
  }
};

export const getAllExchangesRealTime = (sponsorId) => {
  try {
    const query = db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("exchanges");

    const snapshot = query.where("status", "==", "active");

    return snapshot;
  } catch (error) {
    console.log(error);
  }
};

export const getAllPromosById = async (sponsorId) => {
  try {
    const promoCollection = await db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("promotions")
      .get();

    const promos = [];

    promoCollection.docs.forEach((doc) => {
      promos.push({ id: doc.id, ...doc.data(), selected: false });
    });

    return promos;
  } catch (error) {
    console.log(error);
  }
};

export const getAllPromos = () => {
  try {
    const promotions = db.collectionGroup("promotions");
    return promotions;
  } catch (error) {
    console.log(error);
  }
};
export const getAllActivePromos = async () => {
  try {
    const promotions = db
      .collectionGroup("promotions")
      .where("status", "==", "active");
    return promotions;
  } catch (error) {
    console.log(error);
  }
};
export const getSponsorPromos = async () => {
  const promoList = [];
  try {
    const querySnapshot = db
      .collectionGroup("promotions")
      .where("status", "==", "active")
      .orderBy("cdate", "desc")
      .get();

    querySnapshot.docs.forEach((doc) => {
      const docRef = doc.ref;
      const parentCollectionRef = docRef.parent; // CollectionReference
      promoList.push({
        sponsorId: parentCollectionRef.parent.id,
        exchangeId: doc.id,
        ...doc.data(),
      });
    });
    return promoList;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getProducts = async () => {
  try {
    const products = [];
    const itemsFromFs = await db.collection("products").get();
    itemsFromFs.docs.forEach((product) =>
      products.push({ id: product.id, ...product.data() })
    );

    return products;
  } catch (error) {
    console.log(error);
  }
};

export const getPlans = async () => {
  try {
    const plans = [];
    const itemsFromFs = await db.collection("plans").get();
    itemsFromFs.docs.forEach((plan) =>
      plans.push({ id: plan.id, ...plan.data() })
    );

    return plans;
  } catch (error) {
    console.log(error);
  }
};

export const getOnePlan = async (planId) => {
  try {
    const plan = await db.collection("plans").doc(planId).get();

    return plan.data();
  } catch (error) {
    console.log(error);
  }
};

export const getAllPromosRealTime = (sponsorId) => {
  try {
    const query = db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("promotions");

    const snapshot = query.where("status", "==", "active");

    return snapshot;
  } catch (error) {
    console.log(error);
  }
};

export const getActivityHistory = async (userId) => {
  try {
    let history = [];
    const activity = await db
      .collection("users")
      .doc(userId)
      .collection("activityHistory")
      .get();

    activity.docs.forEach((doc) => {
      history.push({ ...doc.data(), id: doc.id });
    });

    return history;
  } catch (error) {
    console.log();
  }
};

export const getRedeemHistory = async (sponsorId) => {
  let history = [];

  const redeems = await db
    .collection("sponsors")
    .doc(sponsorId)
    .collection("redeemHistory")
    .get();

  redeems.docs.forEach((doc) => {
    history.push({ ...doc.data(), id: doc.id });
  });

  return history;
};

export const getCausesRequestor = async () => {
  try {
    const requestorsArray = [];
    const itemsFromFs = await db.collection("organizations").get();

    itemsFromFs.docs.forEach((doc) => {
      requestorsArray.push({ ...doc.data(), id: doc.id });
    });

    /*for (let cause of itemsFromFs.docs) {
      const userRequestor = await getUser(cause.data().userRef);
      causesToArray.push({
        id: cause.id,
        ...cause.data(),
        requestor: userRequestor.name,
      });
    }

    console.log(causesToArray);*/

    return requestorsArray;
  } catch (error) {
    console.log(error);
  }
};

export const getAllDonors = async (organizationId, causeId) => {
  try {
    let donors = [];

    const data = await db
      .collection("organizations")
      .doc(organizationId)
      .collection("causes")
      .doc(causeId)
      .collection("donors")
      .get();

    data.docs.forEach((doc) => {
      donors.push(doc.data());
    });

    return donors;
  } catch (error) {
    console.log(error);
  }
};

export const getAllCausesByOrganization = async () => {
  try {
    const causesToArray = [];
    const organizationsFromFs = await db.collection("organizations").get();
    let cont = 0;

    for (let organization of organizationsFromFs.docs) {
      //console.log(organization.id);
      const organizationCauses = await getAllCauses(organization.id);

      for (const cause of organizationCauses) {
        causesToArray.push({
          id: cause.id,
          organizationId: organization.id,
          ...organization.data(),
          requestor: organization.data().organizationName,
          ...cause,
        });
      }
      /*const causesData  = organizationCauses[cont];
      causesToArray.push({
        organizationId: organization.id,
        ...organization.data(),
        requestor: organization.data().organizationName,
        ...organizationCauses[cont]
      });
      cont++;
    }*/
    }

    return causesToArray;
  } catch (error) {
    console.log(error);
  }
};

export const getAllCauses = async (organizationId) => {
  try {
    const causesToArray = [];
    const itemsFromFs = await db
      .collection("organizations")
      .doc(organizationId)
      .collection("causes")
      .get();

    itemsFromFs.docs.forEach((doc) => {
      causesToArray.push({ id: doc.id, ...doc.data() });
    });

    return causesToArray;
  } catch (error) {
    console.log(error);
  }
};

/*export const getAllCauses = async () => {
  try {
    const causesToArray = [];
    const itemsFromFs = await db.collection("causes").get();

    for (let cause of itemsFromFs.docs) {
      const userRequestor = await getUser(cause.data().userRef);
      causesToArray.push({
        id: cause.id,
        ...cause.data(),
        requestor: userRequestor.name,
      });
    }

    console.log(causesToArray);

    return causesToArray;
  } catch (error) {
    console.log(error);
  }
};*/

export const getUserExchanges = async (userId) => {
  try {
    let exchanges = [];

    const exchangesCollectionRef = getUserInnerCollection("redeems", userId);
    const data = await exchangesCollectionRef.get();

    data.docs.forEach((doc) => {
      exchanges.push({ id: doc.id, ...doc.data() });
    });

    return exchanges;
  } catch (error) {
    console.log(error);
  }
};

export const getUserAdminHistory = async (userId) => {
  try {
    let history = [];

    const historyCollectionRef = getUserInnerCollection("adminHistory", userId);
    const data = await historyCollectionRef
      .orderBy("modificationDate", "desc")
      .get();

    data.docs.forEach((doc) => {
      history.push({ id: doc.id, ...doc.data() });
    });

    return history;
  } catch (error) {
    console.log(error);
  }
};

export const getProductAdminHistory = async (productId) => {
  try {
    let history = [];

    const data = await db
      .collection("products")
      .doc(productId)
      .collection("adminHistory")
      .orderBy("modificationDate", "desc")
      .get();

    data.docs.forEach((doc) => {
      history.push({ id: doc.id, ...doc.data() });
    });

    return history;
  } catch (error) {
    console.log(error);
  }
};

export const getPlanAdminHistory = async (planId) => {
  try {
    let history = [];

    const data = await db
      .collection("plans")
      .doc(planId)
      .collection("adminHistory")
      .orderBy("modificationDate", "desc")
      .get();

    data.docs.forEach((doc) => {
      history.push({ id: doc.id, ...doc.data() });
    });

    return history;
  } catch (error) {
    console.log(error);
  }
};

export const getInsurerAdminHistory = async (insurerIdList) => {
  try {
    let history = [];
    let data;

    for (const insurerId of insurerIdList) {
      data = await db
        .collection("insurers")
        .doc(insurerId)
        .collection("adminHistory")
        .orderBy("modificationDate", "desc")
        .get();

      data.docs.forEach((doc) => {
        history.push({ id: doc.id, ...doc.data() });
      });
    }

    return history;
  } catch (error) {
    console.log(error);
  }
};

export const getSponsorAdminHistory = async (sponsorId) => {
  try {
    let history = [];

    const data = await db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("adminHistory")
      .orderBy("modificationDate", "desc")
      .get();

    data.docs.forEach((doc) => {
      history.push({ id: doc.id, ...doc.data() });
    });

    return history;
  } catch (error) {
    console.log(error);
  }
};

export const getPostAdminHistory = async (blogId) => {
  try {
    let history = [];

    console.log(blogId);

    const data = await db
      .collection("blogs")
      .doc(blogId)
      .collection("adminHistory")
      .orderBy("modificationDate", "desc")
      .get();

    data.docs.forEach((doc) => {
      history.push({ id: doc.id, ...doc.data() });
    });

    return history;
  } catch (error) {
    console.log(error);
  }
};

//Getting single instance

export const getUser = async (userId) => {
  try {
    const result = await db.collection("users").doc(userId).get();
    const user = { id: result.id, ...result.data() };

    return user;
  } catch (error) {
    console.log(error);
  }
};

export const getUserRealTime = (userId) => {
  return db.collection("users").doc(userId);
};

export const getUserInnerCollection = (collectionName, userId) => {
  try {
    const innerCollection = db
      .collection("users")
      .doc(userId)
      .collection(collectionName);

    return innerCollection;
  } catch (error) {
    console.log(error);
  }
};

export const getUserPrivateData = async (userId) => {
  try {
    const privateDataCollectionRef = getUserInnerCollection("private", userId);

    const privateData = await privateDataCollectionRef.doc("data").get();

    return privateData.data();
  } catch (error) {
    console.log(error);
  }
};

export const getUserWallet = async (userId) => {
  try {
    const privateDataCollectionRef = getUserInnerCollection("private", userId);

    const wallet = await privateDataCollectionRef.doc("wallet").get();

    return wallet.data();
  } catch (error) {}
};

export const getUserMetaData = async (userId) => {
  try {
    const metaDataCollectionRef = getUserInnerCollection("metadata", userId);

    const metaData = await metaDataCollectionRef.doc("preferences").get();

    return metaData.data();
  } catch (error) {
    console.log(error);
  }
};

export const getUserPaymentHistory = async (userId, lastVisible, limit = 3) => {
  try {
    const paymentHistory = [];
    const privateDataCollectionRef = getUserInnerCollection("private", userId);
    let query;

    if (lastVisible) {
      query = privateDataCollectionRef
        .doc("wallet")
        .collection("paymentHistory")
        .orderBy("transactionDate", "desc")
        .startAfter(lastVisible)
        .limit(limit);
    } else {
      query = privateDataCollectionRef
        .doc("wallet")
        .collection("paymentHistory")
        .orderBy("transactionDate", "desc")
        .limit(limit);
    }

    const data = await query.get();

    data.docs.forEach((doc) => {
      paymentHistory.push({ id: doc.id, ...doc.data(), selected: false });
    });

    return {
      paymentHistory,
      lastVisible: data.docs[data.docs.length - 1],
    };
  } catch (error) {}
};

// export const getUserPaymentHistory = async (userId, limit = 3, lastVisible) => {
//   try {
//     const paymentHistory = [];
//     const privateDataCollectionRef = getUserInnerCollection("private", userId);
//     const data = await privateDataCollectionRef
//       .doc("wallet")
//       .collection("paymentHistory")
//       .orderBy("transactionDate", "desc")
//       .get();

//     data.docs.forEach((doc) => {
//       paymentHistory.push({ id: doc.id, ...doc.data(), selected: false });
//     });

//     return paymentHistory;
//   } catch (error) {}
// };

export const getProduct = (productId) => {
  try {
    const product = db.collection("products").doc(productId);

    return product;
  } catch (error) {
    console.log(error);
  }
};

export const getSponsor = (sponsorId) => {
  try {
    const sponsor = db.collection("sponsors").doc(sponsorId);

    return sponsor;
  } catch (error) {
    console.log(error);
  }
};

/*export const getCause = (causeId) => {
  try {
    const cause = db.collection("causes").doc(causeId);

    return cause;
  } catch (error) {
    console.log(error);
  }
};*/

export const getCause = (causeId, organizationId) => {
  try {
    const cause = db
      .collection("organizations")
      .doc(organizationId)
      .collection("causes")
      .doc(causeId);

    return cause;
  } catch (error) {
    console.log(error);
  }
};

export const getAchievement = (achievementId) => {
  try {
    const achievement = db.collection("achievements").doc(achievementId);
    return achievement;
  } catch (error) {
    console.log(error);
  }
};

/*export const getOrganization = async (organizationId) => {
  try {
    const organization = await db
      .collection("organizations")
      .doc(organizationId)
      .get();

    return organization.data();
  } catch (error) {
    console.log(error);
  }
};*/

export const getOrganization = (organizationId) => {
  try {
    return db.collection("organizations").doc(organizationId);

    //return organizationRef;
  } catch (error) {
    console.log(error);
  }
};

//Getting by query
export const getPendingExchanges = (sponsorId) => {
  try {
    const exchangesRef = db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("exchanges");
    const query = exchangesRef.where("status", "==", "pending");
    //.where("fieldName", "in", ["fieldValue1", "fieldValue2"])

    return query;
  } catch (error) {
    console.log(error);
  }
};

export const getRequests = (sponsorId) => {
  try {
    const queryExchanges = getPendingExchanges(sponsorId);
    const queryPromotions = getPendingPromotions(sponsorId);

    let totalExchanges,
      totalPromos = 0;

    queryExchanges.onSnapshot((querySnapshot) => {
      const exchangesArr = [];
      querySnapshot.forEach((doc) => {
        exchangesArr.push({ id: doc.id, ...doc.data(), type: "exchange" });
      });
      totalExchanges = exchangesArr.length;
      console.log(totalExchanges);
    });

    queryPromotions.onSnapshot((querySnapshot) => {
      const promosArr = [];
      querySnapshot.forEach((doc) => {
        promosArr.push({ id: doc.id, ...doc.data(), type: "promo" });
      });
      totalPromos = promosArr.length;
      console.log(totalPromos);
    });

    const total = totalExchanges + totalPromos;

    return total;
  } catch (error) {
    console.log(error);
  }
};

export const getPendingPromotions = (sponsorId) => {
  try {
    const exchangesRef = db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("promotions");
    const query = exchangesRef.where("status", "==", "pending");

    return query;
  } catch (error) {
    console.log(error);
  }
};
export const getRedeemsFromSponsor = (sponsorId) => {
  try {
    const redeemData = [];
    const redeemsFromFB = db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("redeemHistory");
    const query = redeemsFromFB
      .where("status", "==", "pendingRefund")
      .where("status", "==", "refunded");
    query.onSnapshot((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        redeemData.push({ id: doc.id, ...doc.data(), type: "redeem" });
      });
    });

    return redeemData;
  } catch (error) {
    console.log(error);
  }
};
export const getAllClients = async () => {
  try {
    let clients = [];
    const ref = db.collection("users");

    const query = await ref.where("isActiveMember", "==", true).get();
    query.docs.forEach((doc) => {
      clients.push({ ...doc.data(), id: doc.id });
    });

    return clients;
  } catch (error) {
    console.log(error);
  }
};

export const getClientsPreparedData = async () => {
  try {
    let clients = [];
    let wallets = [];
    let donationsByUser = [];
    const ref = db.collection("users");

    const getPlanType = (index) => {
      if (wallets[index]?.renewDate) {
        return "Renovacion";
      } else {
        return "Nuevo";
      }
    };

    const getStatus = (billingDate) => {
      const currentMonthNumber = new Date().getMonth();
      const nextBillingMonth = billingDate;

      if (currentMonthNumber >= nextBillingMonth) {
        return "Due";
      } else {
        return "Activo";
      }
    };

    const getUserDonations = (index) => {
      const total = donationsByUser[index]?.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.sportCoinsDonated;
        },
        0
      );

      return total;
    };

    const query = await ref.where("isActiveMember", "==", true).get();
    query.docs.forEach((doc) => {
      clients.push({ ...doc.data(), id: doc.id });
    });

    for (const client of clients) {
      const userWallet = await getUserWallet(client.id);
      const donations = await getAllDonationsByUser(client.id);
      wallets.push(userWallet);
      donationsByUser.push(donations);
    }

    const preparedData = clients.map((client, index) => {
      return {
        ...client,
        planStatus: getPlanType(index),
        status: getStatus(wallets[index].nextBillingDate),
        donated: getUserDonations(index),
        lastRenovation: wallets[index].billedDate,
      };
    });

    return preparedData;
  } catch (error) {
    console.log(error);
  }
};

export const getAllDonationsByUser = async (userId) => {
  try {
    let userDonations = [];

    const donationsRef = getUserInnerCollection("donations", userId);

    const data = await donationsRef.get();

    data.docs.forEach((doc) => {
      userDonations.push({ ...doc.data(), id: doc.id });
    });

    return userDonations;
  } catch (error) {}
};

export const getSponsorByName = async (sponsorName) => {
  try {
    let sponsors = [];

    const data = await db
      .collection("sponsors")
      .where("name", "==", sponsorName)
      .get();

    data.docs.forEach((doc) => {
      sponsors.push({ ...doc.data(), id: doc.id });
    });

    return sponsors;
  } catch (error) {
    console.log(error);
  }
};

//Creating instances
export const createCause = async (organizationData, causeData) => {
  try {
    const organizationRef = await db
      .collection("organizations")
      .add(organizationData);

    await db
      .collection("organizations")
      .doc(organizationRef.id)
      .collection("causes")
      .add(causeData);
    console.log("documento creado");
  } catch (error) {
    console.log(error);
  }
};

export const createAchievement = async (achievementData) => {
  try {
    const doc = await db.collection("achievements").add(achievementData);
    console.log("achievement creado");
    return doc.id;
  } catch (error) {
    console.log(error);
  }
};

export const createAchievementImage = async (imageUpload, id) => {
  try {
    const path = `achievements/${id}.png`;
    const fileUrl = await uploadFile(path, imageUpload);

    const newAchievementImg = { imageUrl: fileUrl };

    await updateAchievementImage(id, newAchievementImg);
  } catch (error) {
    console.log(error);
  }
};

export const createMember = async (memberData) => {
  try {
    const doc = await db.collection("admins").add(memberData);
    console.log("member created");
    return doc.id;
  } catch (error) {
    console.log(error);
  }
};

export const createB2b = async (data) => {
  try {
    await db.collection("associations").add(data);
    //const doc = await db.collection("sponsors").add(sponsorData);
  } catch (error) {
    console.log(error);
  }
};

// export const createMemberImage = async (imageUpload, id) => {
//   try {
//     const path = `admins/${id}.png`;
//     const fileUrl = await uploadFile(path, imageUpload);

//     const newMemberImg = { imageUrl: fileUrl };

//     await updateMemberImage(id, newMemberImg);
//   } catch (error) {
//     console.log(error);
//   }
// };

export const createMemberImageEdit = async (imageUpload, id) => {
  try {
    const path = `admins/${id}.png`;
    const fileUrl = await uploadFile(path, imageUpload);
    console.log(fileUrl);
    return fileUrl;
  } catch (error) {
    console.log(error);
  }
};

export const createProduct = async (productData) => {
  try {
    await db.collection("products").add(productData);
  } catch (error) {
    console.log(error);
  }
};
export const createPlan = async (planData) => {
  try {
    const newDoc = await db.collection("plans").add(planData);

    return newDoc.id;
  } catch (error) {
    console.log(error);
  }
};

export const getSponsorLocations = async () => {
  try {
    const sponsorLocations = [];

    const sponsorsSnapshot = await db.collection("sponsors").get();

    for (const sponsorDoc of sponsorsSnapshot.docs) {
      const businessName = sponsorDoc.data().name;
      const sponsorId = sponsorDoc.id;

      const branchesSnapshot = await db
        .collection("sponsors")
        .doc(sponsorId)
        .collection("branches")
        .get();

      branchesSnapshot.docs.forEach((branchDoc) => {
        const location = branchDoc.data().location;
        sponsorLocations.push({ businessName, location });
      });
    }

    return sponsorLocations;
  } catch (error) {
    console.log(error);
  }
};
export const addBranchesToSponsor = async (sponsorId, branchesData) => {
  try {
    const sponsorRef = db.collection("sponsors").doc(sponsorId);
    const branchesCollectionRef = sponsorRef.collection("branches");

    for (const branchData of branchesData) {
      await branchesCollectionRef.add(branchData);
    }
  } catch (error) {
    console.log(error);
  }
};
export const createSponsor = async (sponsorData, branches) => {
  try {
    const sponsorRef = await db.collection("sponsors").add(sponsorData);

    // If branches are provided, add them to the sponsor
    if (branches && branches.length > 0) {
      const sponsorId = sponsorRef.id;
      const branchesData = branches.map((branchData) => ({
        ...branchData,
        sponsorId,
      }));
      await addBranchesToSponsor(sponsorId, branchesData);
    }

    return sponsorRef.id;
  } catch (error) {
    console.log(error);
  }
};

export const getBranches = async (sponsorId) => {
  const branches = [];

  try {
    const sponsorBranch = await db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("branches")
      .get();

    sponsorBranch.docs.forEach((doc) =>
      branches.push({ id: doc.id, ...doc.data() })
    );

    // console.log(branches);

    return branches;
  } catch (error) {
    console.log(error);
  }
};
export const createBranches = async (sponsorId, branchData) => {
  try {
    const sponsorRef = db.collection("sponsors").doc(sponsorId);
    const branchesCollection = sponsorRef.collection("branches");

    // Add the branch data to the branches collection
    await branchesCollection.add(branchData);
  } catch (error) {
    console.log(error);
  }
};

export const editBranches = async (sponsorId, branchData, branchId) => {
  console.log(branchData, branchId);
  try {
    await db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("branches")
      .doc(branchId)
      .set(branchData, { merge: true });
  } catch (error) {
    console.log(error);
  }
};
export const createBranch = async (sponsorId, branchesData) => {
  try {
    await db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("branches")
      .add(branchesData);
    console.log("documento creado");
  } catch (error) {
    console.log(error);
  }
};

export const getBranch = async (sponsorId, branchId) => {
  try {
    const sponsorRef = db.collection("sponsors").doc(sponsorId);
    const branchesCollection = sponsorRef.collection("branches");

    // Retrieve the branch data from the Firestore collection
    const branchDoc = await branchesCollection.doc(branchId).get();

    if (branchDoc.exists) {
      // Branch document exists, you can access its data using .data()
      const branchData = branchDoc.data();
      return branchData;
    } else {
      // Branch document doesn't exist
      return null;
    }
  } catch (error) {
    console.error("Error getting branch:", error);
    throw error;
  }
};
export const deleteBranch = (sponsorId, branchId) => {
  try {
    db.collection("sponsors")
      .doc(sponsorId)
      .collection("branches")
      .doc(branchId)
      .delete();
    console.log("Sucursal eliminada");
  } catch (error) {
    console.log(error);
  }
};

export const createSponsorImage = async (imageUpload, id) => {
  try {
    const path = `sponsor/${id}/logo`;
    const fileUrl = await uploadFile(path, imageUpload);

    const newSponsorImg = { logo: fileUrl };

    await updateSponsor(id, newSponsorImg);
  } catch (error) {
    console.log(error);
  }
};

export const createExchange = async (sponsorId, exchangeData) => {
  try {
    db.collection("sponsors")
      .doc(sponsorId)
      .collection("exchanges")
      .add(exchangeData);
    console.log("documento creado");
  } catch (error) {
    console.log(error);
  }
};

export const createPromotion = async (sponsorId, promotionData) => {
  try {
    const promoRef = await db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("promotions")
      .add(promotionData);
    // console.log(promoRef.id);
    // console.log("documento creado");

    return promoRef.id;
  } catch (error) {
    console.log(error);
  }
};

export const createPromotionImage = async (
  imageUpload,
  sponsorId,
  promotionId
) => {
  try {
    const path = `sponsor/${sponsorId}/promotions/${promotionId}`;
    console.log(path);
    const fileUrl = await uploadFile(path, imageUpload);

    const newPromotionImg = { imageURL: fileUrl };

    await updatePromotionImage(promotionId, newPromotionImg, sponsorId);
  } catch (error) {
    console.log(error);
  }
};

export const createBanner = (bannerData) => {
  try {
    db.collection("banners")
      .doc("exchange")
      .collection("exchangeCarousel")
      .add(bannerData);
  } catch (error) {
    console.log(error);
  }
};

export const createBadgeImage = async (imageUpload, planId) => {
  try {
    const path = `plans/badges/${planId}/badge`;

    const fileUrl = await uploadFile(path, imageUpload[0]);

    const newBadgeImg = { badgeImg: fileUrl };

    await updatePlan(planId, newBadgeImg);
  } catch (error) {
    console.log(error);
  }
};

export const addAdminHistoryEntry = async (updates, queryPath) => {
  console.log(updates, queryPath);
  try {
    await db.collection(queryPath).add(updates);
    console.log("Documento creado");
  } catch (error) {
    console.log(error);
  }
};

//Deleting instances
export const deleteExchange = async (sponsorId, exchangeId) => {
  try {
    db.collection("sponsors")
      .doc(sponsorId)
      .collection("exchanges")
      .doc(exchangeId)
      .delete();
    console.log("Canje borrado");
  } catch (error) {
    console.log(error);
  }
};

export const deleteSponsor = async (sponsorId) => {
  try {
    db.collection("sponsors").doc(sponsorId).delete();
    console.log("Sponsor eliminado");
  } catch (error) {
    console.log(error);
  }
};

export const deletePromo = async (sponsorId, promotionId) => {
  try {
    db.collection("sponsors")
      .doc(sponsorId)
      .collection("promotions")
      .doc(promotionId)
      .delete();
    console.log("Promocion borrada");
  } catch (error) {
    console.log(error);
  }
};

export const deleteCause = (organizationId, causeId) => {
  try {
    db.collection("organizations")
      .doc(organizationId)
      .collection("causes")
      .doc(causeId)
      .delete();
    console.log("Causa eliminada");
  } catch (error) {
    console.log(error);
  }
};

export const deleteAchievement = (achievementId) => {
  try {
    db.collection("achievements").doc(achievementId).delete();
    console.log("Achievement eliminado");
  } catch (error) {
    console.log(error);
  }
};

export const deleteMember = (memberId) => {
  try {
    db.collection("admins").doc(memberId).delete();
    console.log("Member deleted");
  } catch (error) {
    console.log(error);
  }
};

export const deleteProduct = (productId) => {
  try {
    db.collection("products").doc(productId).delete();
    console.log("Producto eliminado");
  } catch (error) {
    console.log(error);
  }
};

export const deletePlan = (planId) => {
  try {
    db.collection("plans").doc(planId).delete();
    console.log("Plan eliminado");
  } catch (error) {
    console.log(error);
  }
};

export const deleteBanner = (bannerId) => {
  try {
    db.collection("banners")
      .doc("exchange")
      .collection("exchangeCarousel")
      .doc(bannerId)
      .delete();
    console.log("Banner eliminado");
  } catch (error) {
    console.log(error);
  }
};

//Updating instances

export const updatePromotionImage = async (
  promotionId,
  newPromotionImg,
  sponsorId
) => {
  try {
    db.collection("sponsors")
      .doc(sponsorId)
      .collection("promotions")
      .doc(promotionId)
      .set(newPromotionImg, { merge: true });
  } catch (error) {
    console.log(error);
  }
};

export const updateAchievement = async (achievementId, achievementData) => {
  console.log(achievementData);
  try {
    db.collection("achievements")
      .doc(achievementId)
      .set(achievementData, { merge: true });
    console.log("Documento actualizado");
  } catch (error) {
    console.log(error);
  }
};

export const updateAchievementImage = async (id, newAchievementImg) => {
  try {
    db.collection("achievements")
      .doc(id)
      .set(newAchievementImg, { merge: true });
    console.log("Imagen actualizada del logro");
  } catch (error) {
    console.log(error);
  }
};

export const updateMember = async (memberId, memberData) => {
  console.log(memberData);
  try {
    db.collection("admins").doc(memberId).set(memberData, { merge: true });
    console.log("Documento actualizado");
  } catch (error) {
    console.log(error);
  }
};

export const updateMemberImage = async (id, newMemberImg) => {
  try {
    db.collection("admins").doc(id).set(newMemberImg, { merge: true });
    console.log("Member image updated");
  } catch (error) {
    console.log(error);
  }
};

export const updateExchange = async (sponsorId, exchangeId, updates) => {
  console.log(sponsorId, exchangeId, updates);
  try {
    db.collection("sponsors")
      .doc(sponsorId)
      .collection("exchanges")
      .doc(exchangeId)
      .set(updates, { merge: true });
    console.log("Documento actualizado");
  } catch (error) {
    console.log(error);
  }
};

export const updatePromos = async (sponsorId, promotionId, updates) => {
  try {
    db.collection("sponsors")
      .doc(sponsorId)
      .collection("promotions")
      .doc(promotionId)
      .set(updates, { merge: true });
    console.log("Documento actualizado");
  } catch (error) {
    console.log(error);
  }
};
export const handleRefund = async (
  uid,
  sponsorId,
  redeemId,
  refundHistory,
  refundData,
  refundedCoins,
  wallet
) => {
  const refundDatatoAdd = [
    {
      motive: refundData.motive,
      notes: refundData.notes,
    },
  ];

  const newSportCoins = refundedCoins + wallet.sportCoins;
  const newOverallSportCoins = refundedCoins + wallet.overallSportCoins;

  try {
    await db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("redeemHistory")
      .doc(redeemId)
      .update(refundHistory, { refundData: refundDatatoAdd });

    await db.doc(`users/${uid}`).collection("private").doc("wallet").update({
      sportCoins: newSportCoins,
      overallSportCoins: newOverallSportCoins,
    });

    await db
      .collection("users")
      .doc(uid)
      .collection("redeems")
      .doc(redeemId)
      .update(refundHistory, { refundData: refundDatatoAdd });
  } catch (error) {
    console.log(error);
  }
};
export const getUserFromRedeem = async (uid) => {
  try {
    const data = await db.collection("users").doc(uid).get();
    return data.data();
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const declineRefund = async (
  sponsorId,
  redeemId,
  refundHistory,
  refundData
) => {
  console.log(sponsorId, redeemId);
  const refundDataAdd = {
    refundData: [...refundData],
  };
  try {
    db.collection("sponsors")
      .doc(sponsorId)
      .collection("redeemHistory")
      .doc(redeemId)
      .set(refundHistory, refundDataAdd, { merge: true });
  } catch (error) {
    console.log(error);
  }
};

export const updateSponsor = async (sponsorId, updates) => {
  try {
    db.collection("sponsors").doc(sponsorId).set(updates, { merge: true });
    console.log("Documento actualizado");
  } catch (error) {
    console.log(error);
  }
};

export const updateBranch = async (sponsorId, updates, branchId) => {
  try {
    db.collection("sponsors")
      .doc(sponsorId)
      .collection("branches")
      .doc(branchId)
      .set(updates, { merge: true });
    console.log("Documento actualizado");
  } catch (error) {
    console.log(error);
  }
};

export const updateUserData = async (userId, updates) => {
  try {
    db.collection("users").doc(userId).update(updates);
    console.log("Documento actualizado");
  } catch (error) {
    console.log(error);
  }
};

export const updateUserPrivateData = async (userId, updates) => {
  try {
    db.collection("users")
      .doc(userId)
      .collection("private")
      .doc("data")
      .set(updates, { merge: true });
    console.log("Documento actualizado");
  } catch (error) {
    console.log(error);
  }
};

export const updateUserMetaData = async (userId, updates) => {
  try {
    db.collection("users")
      .doc(userId)
      .collection("metadata")
      .doc("preferences")
      .set(updates, { merge: true });
    console.log("Documento actualizado");
  } catch (error) {
    console.log(error);
  }
};

export const updateActivityHistory = async (userId, activityId, updates) => {
  try {
    await db
      .collection("users")
      .doc(userId)
      .collection("activityHistory")
      .doc(activityId)
      .set(updates, { merge: true });
    console.log("Documento actualizado");
  } catch (error) {
    console.log(error);
  }
};

export const updateCause = async (organizationId, causeId, updatesCause) => {
  console.log(updatesCause);
  try {
    db.collection("organizations")
      .doc(organizationId)
      .collection("causes")
      .doc(causeId)
      .set(updatesCause, { merge: true });
    console.log("Documento actualizado");
  } catch (error) {
    console.log(error);
  }
};

export const updateOrganization = async (
  organizationId,
  causeId,
  updatesOrganizations
) => {
  try {
    await db
      .collection("organizations")
      .doc(organizationId)
      .collection("causes")
      .doc(causeId)
      .set(updatesOrganizations, { merge: true });
    console.log("Documento actualizado");
  } catch (error) {
    console.log(error);
  }
};

export const updateProductState = async (productId, productUpdates) => {
  try {
    await db
      .collection("products")
      .doc(productId)
      .set(productUpdates, { merge: true });
  } catch (error) {
    console.log(error);
  }
};

export const updateProduct = async (productId, productUpdates) => {
  try {
    await db
      .collection("products")
      .doc(productId)
      .set(productUpdates, { merge: true });
    console.log("Producto actualizado");
  } catch (error) {
    console.log(error);
  }
};

export const updatePlan = async (planId, planUpdates) => {
  try {
    console.log(planId, planUpdates);
    await db.collection("plans").doc(planId).set(planUpdates, { merge: true });
    console.log("Plan actualizado");
  } catch (error) {
    console.log(error);
  }
};

export const updateBanner = async (bannerId, updates) => {
  try {
    await db
      .collection("banners")
      .doc("exchange")
      .collection("exchangeCarousel")
      .doc(bannerId)
      .set(updates, { merge: true });
    console.log("Banner actualizado");
  } catch (error) {
    console.log(error);
  }
};

//Creating instances
export const uploadFile = async (path, file) => {
  if (file === null) return;

  try {
    const upload = await storageRef.ref(path).put(file);
    const fileUrl = await upload.ref.getDownloadURL();

    return fileUrl;
  } catch (error) {
    console.log(file, path);
    console.log("No se puede subir el archivo seleccionado");
  }
};

//Counting instances
export const countUsers = async (onCount) => {
  const query = db.collection("users");
  let count = 0;
  query.onSnapshot((snapshot) => {
    snapshot.docChanges().forEach((doc) => {
      if (doc.type === "added") onCount(++count);
      if (doc.type === "removed") onCount(--count);
    });
  });
};

export const countActiveUsers = async (onCount) => {
  const query = db.collection("users");
  let count = 0;
  const results = query
    .where("isActiveMember", "==", true)
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((doc) => {
        if (doc.type === "added") onCount(++count);
        if (doc.type === "removed") onCount(--count);
      });
    });
};

export const countSponsors = async (onCount) => {
  const query = db.collection("sponsors");
  let count = 0;
  const results = query.onSnapshot((snapshot) => {
    snapshot.docChanges().forEach((doc) => {
      if (doc.type === "added") onCount(++count);
      if (doc.type === "removed") onCount(--count);
    });
  });
};

export const countActiveExchanges = async (onCount, sponsorId) => {
  const query = db
    .collection("sponsors")
    .doc(sponsorId)
    .collection("exchanges");

  //const sponsors = await getAllSponsors();
  //collection group

  //let count = 0;
  const results = query
    //.where("status", "==", "active")
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((doc) => {
        if (doc.type === "added") onCount((prevState) => prevState + 1);
        if (doc.type === "removed") onCount((prevState) => prevState - 1);
      });
    });
};
export const countAllActiveExchanges = async (onCount) => {
  const query = db.collectionGroup("exchanges").where("status", "==", "active");
  console.log(query);
  const results = query.onSnapshot((snapshot) => {
    snapshot.docChanges().forEach((doc) => {
      if (doc.type === "added") onCount((prevState) => prevState + 1);
      if (doc.type === "removed") onCount((prevState) => prevState - 1);
    });
  });
  return results;
};

//Authentication
export const onAuthStateChangedListener = () => {
  return auth;
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await auth.signInWithEmailAndPassword(email, password);
};

export const logOut = () => {
  return auth.signOut();
};

export const deactivateSponsor = async (sponsorId) => {
  try {
    const exchanges = await db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("exchanges")
      .get();

    const promos = await db
      .collection("sponsors")
      .doc(sponsorId)
      .collection("promotions")
      .get();

    exchanges.docs.forEach((item) => {
      item.ref.update({ status: "pending" });
    });

    promos.docs.forEach((item) => {
      item.ref.update({ status: "pending" });
    });
  } catch (error) {
    console.log(error);
  }
};

export const getInsurers = async () => {
  try {
    const insurersList = [];
    const result = await db.collection("insurers").get();

    result.docs.forEach((doc) => {
      insurersList.push({ id: doc.id, ...doc.data() });
    });

    return insurersList;
  } catch (error) {
    console.log(error);
  }
};

export const addInsurer = async (insurerData) => {
  try {
    await db.collection("insurers").add(insurerData);
  } catch (error) {
    console.log(error);
  }
};

export const updateInsurer = async (insurerData, insurerId) => {
  try {
    await db.collection("insurers").doc(insurerId).update(insurerData);
  } catch (error) {
    console.log(error);
  }
};
