import { Table } from "react-bootstrap";
import { Stack } from "react-bootstrap";

import ReactPaginate from "react-paginate";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

import TableHeader from "../table/table-header";
import TableBodyHover from "../table/table-body-hover";
import ItemsControl from "../items-control/items-control";
import TableFilters from "../table/table-filters";
import CoinLoader from "./../coin-loader/coin-loader";

import "./plans-table.scss";

import { useMediaQuery } from "@mui/material";

const PlansTable = ({
  setSearchField,
  dropdownList,
  options,
  getAllProducts,
  loading,
  pageCount,
  rows,
  headers,
  handlePageClick,
  setItemsPerPage,
}) => {
  const matchesUp1200 = useMediaQuery("(min-width:1200px)");
  const matchesDown620 = useMediaQuery("(max-width:620px)");

  return (
    <>
      <TableFilters
        options={options}
        dropdowns={matchesUp1200 ? dropdownList : null}
        searchRows={matchesUp1200 ? setSearchField : null}
        handleRefresh={matchesUp1200 ? () => getAllProducts() : null}
        showArrows={matchesDown620}
        product="plan"
      />

      <div className="p-4 bg-light">
        {loading ? (
          <div className="loading-container">
            <CoinLoader size={{ width: 150, height: 150 }} />
          </div>
        ) : (
          <Table
            className="bg-light mb-0 p-4"
            responsive
            style={{ width: "400px !important" }}
          >
            <TableHeader headers={headers} />
            <TableBodyHover
              //rows={currentItems}
              rows={rows}
              columns={headers}
              clickGoTo="/user/"
            />
          </Table>
        )}
      </div>

      <div className="bg-light p-4 rounded-bottom">
        <Stack direction="horizontal">
          <ItemsControl setNumItemPerPage={setItemsPerPage} />

          <div className="ms-auto">
            <ReactPaginate
              breakLabel="..."
              nextLabel={<IoIosArrowForward />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<IoIosArrowBack />}
              renderOnZeroPageCount={null}
              containerClassName="pagination pagination-modifier"
              pageClassName="page-item"
              previousClassName="previus-button"
              nextClassName="next-button"
              pageLinkClassName="page-link page-link-modifier"
              activeClassName="active"
            />
          </div>
        </Stack>
      </div>
    </>
  );
};

export default PlansTable;
